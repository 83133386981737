<template>
  <div class="container">


    <div class="news mt-4 mb-4">
      <h4 class="text-center">Новости</h4>



      <div class="card mt-4">
        <div class="card-body">

          <div class="row">
            <p>
              <b>Международная научная конференция «Сагадиевские чтения – 2022»</b>
            </p>

          </div>
          <div class="row">
            <div class="col-md-12">

              Прием материалов на конференцию начнется с 14.02.2022г. Материалы должны соответствовать
              <router-link to="/articles-formation-requirements" target="_blank">
                требованию к оформлению статей
              </router-link>
              <br>
              Регистрация на конференцию по <router-link to="/conference/conference-author" target="_blank">
              этой
            </router-link> ссылке
            </div>
          </div>


        </div>
      </div>

<!--<router-view></router-view>-->


    </div>

  </div>


</template>

<script>

export default {
  name: "News",
  data() {
    return {

    }
  },
  computed: {

  },

  methods: {

  },

  async mounted() {

  }
}
</script>

<style scoped>

</style>